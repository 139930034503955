import { MouseEventHandler } from 'react'
import * as Icon from 'components/DesignSystemIcons'
import { theme } from 'styles/stitches.config'

type Props = {
  onClick: MouseEventHandler<HTMLSpanElement>
  isUsualItem: boolean
  id?: string
  css?: React.CSSProperties
}

const UsualItemButton = ({ isUsualItem, onClick, css, id }: Props) => (
  <Icon.Aura
    css={{
      color: isUsualItem ? '$red500' : 'transparent',
      padding: 0,
      cursor: 'pointer',
      ...css,
    }}
    onClick={onClick}
    data-testid={`usual-item-mark-${id}`}
  >
    <Icon.Heart
      stroke={
        isUsualItem ? theme.colors.red500.value : theme.colors.grey600.value
      }
    />
  </Icon.Aura>
)

export default UsualItemButton
