// istanbul ignore file
import { useCallback, useRef, useState } from 'react'
import { useRecoilState } from 'recoil'
import { v4 as uuidV4 } from 'uuid'

import { StandardModal } from 'components/Modals'
import { Modal, useModal } from 'components/ModalKit'
import { Button } from 'design-system'

import useNotification from 'hooks/useNotification'
import { cartState } from 'config/states'
import { FavoriteStore, UsualItem } from 'types/api'
import { rebuildCart } from 'utils/cart'
import { useCheckPushNotificationPermission } from './useCheckPushNotificationPermission'

export const useOrderUsualItem = (store: FavoriteStore) => {
  const modalId = useRef('')
  const [loading, setLoading] = useState(false)
  const [cart, setCart] = useRecoilState(cartState)
  const { openModal, closeModal } = useModal()
  const { notifySuccess } = useNotification()
  const { checkPushNotificationPermission } = useCheckPushNotificationPermission()

  const orderUsualItem = useCallback((item: UsualItem) => {
    setLoading(true)

    const cartData = rebuildCart({
      store: {
        id: store.store_id,
        name: store.name,
        address: store.address,
        photo: store.image
      },
      items: [{
        ...item,
        modifiers: item.choices,
      }]
    })

    if (cart.storeId === store.store_id) {
      setCart({
        ...cart,
        items: cart.items.concat(cartData.items).map((cartItem) => ({ ...cartItem, id: uuidV4() }))
      })

      checkPushNotificationPermission()
      setLoading(false)
      notifySuccess(`Added ${item.name} to cart!`)
      return
    }

    if (!cart.storeId) {
      setCart(cartData)

      checkPushNotificationPermission()
      setLoading(false)
      notifySuccess(`Added ${item.name} to cart!`)
      return
    }

    setLoading(false)

    modalId.current = openModal(
      <StandardModal>
        <Modal.Header>Existing cart detected!</Modal.Header>
        <Modal.Main>
          Heads up! By clicking on continue, you will discard your current cart from {cart.storeName}
        </Modal.Main>
        <Modal.Footer>
          <Button onClick={() => {
            setCart(cartData)
            closeModal(modalId.current)
            notifySuccess(`Added ${item.name} to cart!`)
            setLoading(false)
          }} type="button" cta variant="primary" size="large">Continue</Button>
        </Modal.Footer>
      </StandardModal>
    )
  }, [cart, closeModal, notifySuccess, openModal, setCart, store.address, store.image, store.name, store.store_id, checkPushNotificationPermission])

  return {
    orderUsualItem,
    loading
  }
}
