import { Modal, ModalProps, useModal } from 'components/ModalKit'
import useChannel from 'hooks/useChannel'
import { styled } from 'styles/stitches.config'
import { Button, H1 } from 'design-system'

const ModalContent = styled(Modal.Content, {
  padding: '$xxv $s',
  height: '100%',
  background: '$white',
  display: 'inline-flex',
  flexDirection: 'column',
})

const Content = styled('div', {
  flex: 1,

  display: 'inline-flex',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
})

const TextContainer = styled('div', {
  marginTop: '$v',
  color: '$grey1000'
})

const Description = styled('div', {
  fontSize: '$heading5',
  lineHeight: '$heading5',
  marginTop: '$s'
})

const CtaWrapper = styled('div', {
  flex: 0
})

interface Props {
  onClickGrantPushNotification: () => void
}

const PushNotificationRequestModal = ({ modalId, transitionStyles, onClickGrantPushNotification }: Partial<ModalProps> & Props) => {
  /* istanbul ignore next */
  const id = modalId || ''
  /* istanbul ignore next */
  const styles = transitionStyles || {}

  const { closeModal } = useModal()
  const { transmitPushNotificationRequest } = useChannel()

  const handleClose = () => {
    closeModal(modalId)
  }

  const onClickTurnOn = () => {
    onClickGrantPushNotification()
    transmitPushNotificationRequest()
    closeModal(modalId)
  }

  const onClickRefuse = () => {
    closeModal(modalId)
  }

  return (
    <Modal.Root modalId={id} transitionStyles={styles}>
      <Modal.Overlay handleClose={handleClose} />
      <ModalContent>
        <Content>
          <img alt="push-notification" src="/images/push-notification.svg" />
          <TextContainer>
            <H1>Turn on notifications</H1>
            <Description>For updates on your order status and offers from your favorite coffee shops.</Description>
          </TextContainer>
        </Content>
        <CtaWrapper>
          <Button
            data-testid="push-notification-request-button"
            onClick={onClickTurnOn}
            cta
            variant="primary"
            size="large"
            bold
          >Turn on</Button>
          <Button
            css={{ border: 'none', paddingTop: '$xl' }}
            cta
            size="large"
            bold
            variant="secondary"
            data-testid="push-notification-request-ignore-button"
            onClick={onClickRefuse}
          >
            Not now
          </Button>
        </CtaWrapper>
      </ModalContent>
    </Modal.Root>
  )
}

export default PushNotificationRequestModal
