import { compare } from 'compare-versions'
import { useModal } from 'components/ModalKit'
import PushNotificationRequestModal from 'components/PushNotificationRequestModal'
import { LocalStorageKeys } from 'config/localStorageKeys'
import { isToday } from 'date-fns'
import { useCallback } from 'react'
import useDevice from './useDevice'
import { useLocalStorage } from './useLocalStorage'

export const useCheckPushNotificationPermission = () => {
  const [lasRequestPushNotification, setLasRequestPushNotification] = useLocalStorage<string | null>(LocalStorageKeys.LAST_REQUEST_PUSH_NOTIFICATION, null)
  const [pushNotificationAccepted, setPushNotificationAccepted] = useLocalStorage<boolean>(LocalStorageKeys.PUSH_NOTIFICATION_REQUEST_ACCEPTED, false)
  const { openModal } = useModal()
  const { isBrowser, deviceVersion } = useDevice()

  const showRequestPermissionModal = useCallback(() => {
    openModal(
      <PushNotificationRequestModal onClickGrantPushNotification={() => setPushNotificationAccepted(true)} />
    )
  }, [openModal, setPushNotificationAccepted])

  const checkPushNotificationPermission = useCallback(() => {
    if (isBrowser) {
      return
    }

    if (compare(deviceVersion, '3.1.4', '<')) {
      return
    }

    if (pushNotificationAccepted) {
      return
    }

    const askedPermissionToday = !!lasRequestPushNotification && isToday(new Date(lasRequestPushNotification))

    if (askedPermissionToday) {
      return
    }

    const now = new Date()

    setLasRequestPushNotification(now.toString())
    showRequestPermissionModal()
  }, [lasRequestPushNotification, pushNotificationAccepted, setLasRequestPushNotification, showRequestPermissionModal, isBrowser, deviceVersion])

  return {
    checkPushNotificationPermission
  }
}
