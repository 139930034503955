import { Pagination, PaginationOptions } from '@egjs/flicking-plugins'
import '@egjs/flicking-plugins/dist/pagination.css'
import Flicking, { ChangedEvent, FlickingOptions, ViewportSlot } from '@egjs/react-flicking'
import '@egjs/react-flicking/dist/flicking.css'
import type * as Stitches from '@stitches/react'
import React, { createRef, useCallback, useEffect, useLayoutEffect, useMemo, useRef } from 'react'
import { styled } from 'styles/stitches.config'

const CarouselContainer = styled('div')

export type CarouselOptions = Partial<FlickingOptions> & { pagination: boolean, paginationType?: PaginationOptions['type'] }

type CarouselProps = {
  children: React.ReactNode | React.ReactNode[]
  options: CarouselOptions
  defaultIndex?: number
  customCss?: Stitches.CSS
  callback?: (e: ChangedEvent<Flicking>) => void
}

export const Carousel: React.FC<CarouselProps> = ({ children, options, callback, defaultIndex = 0, customCss }) => {
  const { pagination, paginationType = 'bullet', ...carouselOptions } = options
  const flickingRef = useRef<Flicking | null>(null)

  const createChildrenRefs = useCallback(() => {
    const childRefs: Array<React.RefObject<HTMLDivElement>> = []

    if (Array.isArray(children)) {
      for (let i = 0; i < children.length; i++) {
        childRefs.push(createRef<HTMLDivElement>())
      }
    } else {
      childRefs.push(createRef<HTMLDivElement>())
    }

    return childRefs
  }, [children])

  const childrenRefs = useRef<Array<React.RefObject<HTMLDivElement>>>(createChildrenRefs())

  const plugins = useMemo(() => {
    const pluginsArray = []

    if (pagination) pluginsArray.push(new Pagination({ type: paginationType }))

    return pluginsArray
  }, [pagination, paginationType])

  useEffect(() => {
    const flickingInstance = flickingRef.current
    const currentPanelIndex = flickingInstance?.currentPanel?.index

    if (flickingInstance && currentPanelIndex !== undefined && currentPanelIndex !== defaultIndex) {
      flickingInstance.moveTo(defaultIndex)
    }
  }, [defaultIndex])

  useLayoutEffect(() => {
    if (carouselOptions.adaptive) {
      const flickingPanels = flickingRef.current?.panels

      // istanbul ignore next
      if (flickingPanels) {
        flickingPanels.forEach((panel, index) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          childrenRefs.current[index].current = panel.element

          const childHeight = panel.element.firstElementChild?.getBoundingClientRect().height || 0
          const paddingTop = parseInt(window.getComputedStyle(panel.element).paddingTop) || 0
          const paddingBottom = parseInt(window.getComputedStyle(panel.element).paddingBottom) || 0
          const totalHeight = childHeight + paddingTop + paddingBottom + 42

          panel.element.style.height = `${totalHeight}px`
        })

        const flickingInstance = flickingRef.current

        // istanbul ignore next
        if (flickingInstance) {
          flickingInstance.resize()
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <CarouselContainer css={customCss} >
      <Flicking
        {...carouselOptions}
        preventClickOnDrag={true}
        preventDefaultOnDrag={true}
        ref={flickingRef}
        defaultIndex={defaultIndex}
        plugins={plugins}
        onChanged={callback}
        data-testid="carousel"
        style={{ paddingBottom: pagination ? '20px' : '0' }}>
        {Array.isArray(children) ? children.map((child, idx) => {
          return (
            <div className="flicking-panel" key={idx} ref={childrenRefs.current[idx]}>{child}</div>
          )
        }) : <div className="flicking-panel" ref={childrenRefs.current[0]}>{children}</div>}
        {
          pagination && (
            <ViewportSlot>
              <div className="flicking-pagination"></div>
            </ViewportSlot>
          )
        }
      </Flicking>
      <style>{`
        .flicking-viewport {
          transition: height 500ms;
        }

        .flicking-pagination .flicking-pagination-bullet-active {
          background-color: #2E2E2E;
        }
      `}</style>
    </CarouselContainer>
  )
}
