import { keyframes } from '@stitches/react'
import { Box } from 'design-system/box'
import { styled } from 'styles/stitches.config'

const shimmer = keyframes({
  '0%': { backgroundColor: '#EEEEEE' },
  '50%': { backgroundColor: '#D0D0D0' },
  '100%': { backgroundColor: '#EEEEEE' },
})

export const SkeletonSet = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  margin: '0 -$xs',
})

export const Skeleton = styled(Box, {
  flex: '1 0 auto',
  padding: '0 $xs',
  width: '100%',
})

export const Bone = styled('div', {
  backgroundColor: '$grey100',
  borderRadius: '$xs',
  display: 'block',
  height: '1rem',
  margin: '0 0 $xs',
  overflow: 'hidden',
  position: 'relative',
  animation: `${shimmer} 2s infinite`,

  variants: {
    variant: {
      heading1: {
        height: 'var(--joe-lineHeights-heading1)',
      },
      heading2: {
        height: 'var(--joe-lineHeights-heading2)',
      },
      heading3: {
        height: 'var(--joe-lineHeights-heading3)',
      },
      heading4: {
        height: 'var(--joe-lineHeights-heading4)',
      },
      body1: {
        height: 'var(--joe-lineHeights-body1)',
      },
      body2: {
        height: 'var(--joe-lineHeights-body2)',
      },
      body3: {
        height: 'var(--joe-lineHeights-body3)',
      },
    },
  },
})
