import { CSS } from '@stitches/react'
import * as Icon from 'components/DesignSystemIcons'
import React from 'react'
import { styled } from 'styles/stitches.config'
import { LoadingSkeleton } from './components'

const Wrapper = styled('div', {
  position: 'relative',
  padding: '$m $s $m $s',
})

const Header = styled('header', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '$xxs',
  cursor: 'pointer',
})

const Body = styled('div')

const Title = styled('p', {
  fontSize: '$heading3',
  fontWeight: '$bold',
  lineHeight: '$heading3',
})

const Description = styled('span', {
  fontSize: '$heading5',
  lineHeight: '$body1',
  fontWeight: '$normal',
  color: '$grey800',
  display: 'block',
})

const Arrow = styled('div', {
  width: 28,
  height: 20,
  backgroundColor: '$grey200',
  borderRadius: '$xs',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

type GenericContentWidgetProps = {
  title: string
  description?: string
  action?: () => void
  children?: React.ReactNode
  isLoading?: boolean
  css?: CSS
}

export const GenericContentWidget: React.FC<GenericContentWidgetProps> = ({ title, description, action, children, isLoading = false, css }) => {
  if (isLoading) {
    return <LoadingSkeleton />
  }

  return (
    <Wrapper>
      <Header onClick={action} data-testid="widget-action">
        <Title>{title}</Title>
        {action && <Arrow><Icon.ChevronRight size={12} /></Arrow>}
      </Header>
      <Description>{description}</Description>
      <Body css={css}>{children}</Body>
    </Wrapper>
  )
}

export default GenericContentWidget
