import Big from 'big.js'
import * as Icon from 'components/DesignSystemIcons'
import ImageWithAspectRatio from 'components/ImageWithAspectRatio'
import UsualItemButton from 'components/UsualItemButton'
import { map, take } from 'lodash'
import Link from 'next/link'
import { MouseEventHandler, useCallback, useMemo } from 'react'
import { AddToCartBtn, Item, ItemImage, ItemInfo, ItemModifiers, ItemName, ItemPrice } from 'screens/my-joe/sections/FeaturedItems/components'
import { styled } from 'styles/stitches.config'
import { OrderMyJoeItem } from 'types/api'
import { formatMoney } from 'utils/money'
import { calculateUsualItemPrice, useToggleFavorite } from 'utils/usualItems'

const styles = { paddingRight: '$s' }

const PriceContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
})

interface Props {
  item: OrderMyJoeItem
  storeId: string
  onAdd?: () => void
  onClickUsualItemMark?: () => void
  loading?: boolean
}

const getModifiers = (modifiers: OrderMyJoeItem['modifiers']) =>
  map(take(modifiers, 2), (modifier) => `${modifier.optionName}: ${modifier.name}`).join(', ')

export const FavEligibleItemCard = ({ item, storeId, onAdd, onClickUsualItemMark: onUnmarkedUsualItem }: Props) => {
  const price = item.price ? Big(item.price).div(100).toNumber() : calculateUsualItemPrice(item)
  // istanbul ignore next
  const usualItemsIds = useMemo(() => item.usualItemId ? { [item.id]: item.id } : {}, [item.id, item.usualItemId])

  const { toggleFavorite, isFavorite } = useToggleFavorite(usualItemsIds)

  const onClickUsualItemMark: MouseEventHandler<HTMLSpanElement> = useCallback((event) => {
    event.preventDefault()
    event.stopPropagation()

    // istanbul ignore next
    if (!onUnmarkedUsualItem) return

    onUnmarkedUsualItem()
    toggleFavorite(item.id)
  }, [item.id, onUnmarkedUsualItem, toggleFavorite])

  return (
    <Item css={styles}>
      <Link
        href={{
          pathname: '/explore/stores/[storeId]/[itemId]',
          query: {
            storeId,
            itemId: item.id,
          },
        }}
        key={item.id}
        passHref
      >
        <ItemImage>
          {!!onUnmarkedUsualItem && (
            <UsualItemButton
              id={item.id}
              css={{ position: 'absolute', zIndex: 1, top: 3, left: 3, padding: '$xxs' }}
              onClick={onClickUsualItemMark}
              isUsualItem={isFavorite(item.id)}
            />
          )}
          {
            item.photo ?
              // istanbul ignore next
              <ImageWithAspectRatio aspectRatio={60} alt={item.name} src={item.photo} />
              : <img src="/images/menu/hot-drinks.png" alt={item.name} />
          }
        </ItemImage>
      </Link>
      <ItemInfo>
        <ItemName>{item.size.name} {item.name}</ItemName>
        <ItemModifiers>{getModifiers(item.modifiers)}</ItemModifiers>
        <PriceContainer>
          <ItemPrice>{formatMoney(price)}</ItemPrice>
          <AddToCartBtn css={{ position: 'static' }} onClick={onAdd} data-testid="add-to-cart-fav-btn">
            <Icon.Plus size={12} />
          </AddToCartBtn>
        </PriceContainer>
      </ItemInfo>
    </Item>
  )
}
