import Big from 'big.js'
import { useState } from 'react'
import { OrderMyJoeItem, UsualItem } from 'types/api'

export const calculateUsualItemPrice = (item: OrderMyJoeItem | UsualItem): number => {
  const { size } = item

  const options: {
    id: string
    name: string
    price?: string | number
  }[] = 'choices' in item ? [...item.choices] : [...item.modifiers]

  // istanbul ignore else
  if (size) {
    options.unshift(size)
  }

  const price = options.reduce(
    (totalPrice, option) => totalPrice.add(Big(option.price ?? 0)),
    Big(0)
  )

  return price.div(100).toNumber()
}

type FavoritesState = Record<string, string>

export const useToggleFavorite = (prevFavItems: FavoritesState) => {
  const [favItems, setFavItems] = useState<FavoritesState>(prevFavItems)

  const toggleFavorite = (itemId: string) => {
    setFavItems((prevFavItems) => {
      if (prevFavItems[itemId]) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { [itemId]: _, ...rest } = prevFavItems
        return rest
      } else {
        return { ...prevFavItems, [itemId]: itemId }
      }
    })
  }

  const isFavorite = (itemId: string) => !!favItems[itemId]

  return { favItems, toggleFavorite, isFavorite }
}
