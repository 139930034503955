// istanbul ignore file
import { Carousel, CarouselOptions } from 'components/Carousel'
import { FavEligibleItemCard } from 'components/FavEligibleItemCard'
import GenericContentWidget from 'components/GenericContentWidget'
import { useOrderUsualItem } from 'hooks/useOrderUsualItem'
import { omit } from 'lodash'
import Router from 'next/router'
import { ItemCard } from 'screens/my-joe/sections/FeaturedItems/components'
import { styled } from 'styles/stitches.config'
import { FavoriteStore, OrderMyJoeItem, UsualItem } from 'types/api'
import { StoreTopOrderedItem } from 'types/api/store'

export const Container = styled('div', {
  marginTop: '$xxl',
})

export const FavoriteItem = ({ item, storeId, loading, orderUsualItem, handleOnClickUsualItemMark }: {
  item: UsualItem | StoreTopOrderedItem
  storeId: string
  loading: boolean
  orderUsualItem: (item: UsualItem) => void
  handleOnClickUsualItemMark: (item: UsualItem) => void
}) => {
  const isTopOrderItem = 'sizeId' in item

  return (
    isTopOrderItem ?
      <ItemCard key={item.name} item={item} storeId={storeId} css={{ paddingRight: '$s' }} />
      :
      <FavEligibleItemCard
        key={item.id}
        storeId={storeId}
        item={{
          ...item,
          modifiers: item.choices,
        }}
        loading={loading}
        onAdd={() => orderUsualItem(item)}
        onClickUsualItemMark={() => handleOnClickUsualItemMark(item)}
      />
  )
}

export const FavoriteStoreCard = ({ store, onClickUsualItemMark }: {
  store: FavoriteStore
  onClickUsualItemMark: (item: OrderMyJoeItem, storeId: string) => void
}) => {
  const onClickCard = () => {
    Router.push(`/explore/stores/${store.store_id}`)
  }

  const handleOnClickUsualItemMark = (item: UsualItem) => onClickUsualItemMark({
    ...omit(item, [
      'choices'
    ]),
    modifiers: item.choices
  }, store.store_id)

  const { loading, orderUsualItem } = useOrderUsualItem(store)

  return (
    <GenericContentWidget title={store.name} action={onClickCard} data-testid="generic-content-widget">
      <Carousel options={{ align: 'prev', panelsPerView: 2.5 } as CarouselOptions} customCss={{ marginRight: '-$s' }}>
        {store.items.map((item) => (
          <div key={item.id} data-testid="favorite-store-card">
            <FavoriteItem
              item={item}
              storeId={store.store_id}
              loading={loading}
              orderUsualItem={orderUsualItem}
              handleOnClickUsualItemMark={handleOnClickUsualItemMark}
            />
          </div>
        ))}
      </Carousel>
    </GenericContentWidget>
  )
}
